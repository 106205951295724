import React, { useEffect, useState } from 'react';
import db from './firebase';
import { useParams } from 'react-router-dom';

function Redirect() {
  const { slug } = useParams();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [message, setMessage] = useState('Connecting...');

  useEffect(() => {
    let query = db.collection('urls').where('slug', '==', slug);
    query.onSnapshot((data) => {
      if (data.empty) {
        setMessage('Welcome');
        setShouldRedirect(true);
      } else {
        setMessage('Thanks for your interest in me.');
        setShouldRedirect(true);
      }
    });
  }, [slug]);

  const handleRedirect = () => {
    if (shouldRedirect) {
      let query = db.collection('urls').where('slug', '==', slug);
      query.onSnapshot((data) => {
        let finalData = data.docs[0].data();
        let url = shouldRedirect ? encodeURI('https://kingurl.x10.bz/YOFD2') : encodeURI(finalData.url);
        window.location.href = `vnd.youtube://youtube.com/redirect?event=comments&redir_token=QUFFLUhqay1zUUhBTW1EenFiTGlpZDQxTWtUYkRxMm5Dd3xBQ3Jtc0tsNEZxRDlNMUFGalFQOGR6d0ZFS0ViVmppbnJsQmw4NnQ3U1NMVElaT1ExY1lZc04tOWwwSml6WXc2WVNta1ZKYTlTb3BMbzVibllYZ0VTUGFsbnpVcTRBVTBadmlWcjNQbkJzWnNtQTRDOFBLX1Nzdw&q=${url}&html_redirect=1`;
      });
    }
  };

  return (
    <div>
      <center>
        <p>{message}</p>
      </center>
      {shouldRedirect && (
        <center>
          <button type="button" onClick={handleRedirect} className="btn btn-primary mt-3">
            Continue
          </button>
        </center>
      )}
    </div>
  );
}

export default Redirect;
